/* index.css */

/* Reset margin and set default body styles */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Apply padding for smaller screens */
@media (max-width: 768px) {
  .container {
    padding: 0 1rem; /* Adds padding on mobile to prevent content from touching screen edges */
  }
}

/* Apply padding for larger screens */
@media (min-width: 768px) {
  .container {
    padding: 0 2rem; /* Adds padding on larger screens for a cleaner layout */
  }
}
